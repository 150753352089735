export const env = {
//  apiHost: "http://localhost:3033/",
//  frontUrl: "http://localhost:3000/",

  // apiHost: "https://wxdlpro.io:3033/",
  // frontUrl: "https://wxdlpro.io/",

  apiHost: "https://dxsawertghjklmnvbcxzqwert.wxdlpro.io:8085/",
  frontUrl: "https://wxdlpro.io/",

  // apiHost: "https://sdfxhgmnbjfgyhbgjjb.wxdlpro.io:3033/",
  // frontUrl: "https://sdfxhgmnbjfgyhbgjjb.wxdlpro.io:3000/",

  upload_preset: "f3l6gwtp",
  cloud_name: "dg2vjjlpm",
};
